<template>
  <div class="category bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>

      <el-button type="primary" @click="addclick">新增</el-button>
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="name" align="center" label="集配中心">
          <template slot-scope="scope">
            {{ scope?.row?.business?.name || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="type" align="center" label="类型">
          <template slot-scope="scope">
            {{
              typeList.find((item) => {
                return item.value == scope.row.type;
              }).name
            }}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="标题">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.title) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="图片">
          <template slot-scope="scope">
            <el-image
              style="width: 30px; height: 30px"
              :src="scope.row.url"
              :preview-src-list="[scope.row.url]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="顺序">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.listorder) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 1">启用</el-tag>
            <el-tag type="warning" v-else>停用</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="selectda(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 
    一级分类 -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="800px"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="集配业务中心" prop="logistic_business_id">
            <el-select
              v-model="ruleForm.logistic_business_id"
              clearable
              placeholder="选择业务中心"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="ruleForm.type" clearable placeholder="选择类型">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题" prop="title">
            <div>
              <el-input
                v-model="ruleForm.title"
                placeholder="请输入标题"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="上传图片" prop="url">
            <el-upload
              v-loading="loadingImage"
              element-loading-text="正在上传..."
              action="#"
              :on-preview="handlePictureCardPreview"
              :class="{ hide: url.length == 1 }"
              :file-list="url"
              list-type="picture-card"
              :http-request="
                (file) => {
                  return uploadFile(file, 'url');
                }
              "
              :before-remove="
                (file, fileList) => {
                  return deleteFile(file, fileList, 'url');
                }
              "
              :limit="1"
            >
              <i slot="trigger" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="顺序" prop="listorder">
            <div>
              <el-input
                v-model="ruleForm.listorder"
                placeholder="请输入顺序"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="跳转链接" prop="href">
            <div>
              <el-input
                v-model="ruleForm.href"
                placeholder="请输入跳转链接"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="ruleForm.status">
              <el-radio label="1">启用</el-radio>
              <el-radio label="0">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tianku">确认</el-button>
      </span>
    </el-dialog>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog :visible.sync="dialogImage">
        <img width="100%" :src="bigImage" alt="" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { postBannerUpload, getBannerType } from "@/api/generalControl/banner";
import { compress } from "image-conversion";
export default {
  name: "category",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      loadingImage: false,
      from: {
        logistic_business_id: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        logistic_business_id: "",
        page: 1,
        pageSize: 10,
      },
      currentPage: 1,
      tableData: [],
      listData: [],
      parentDisabled: false,
      total: 0,
      cateName: "",
      //列表页面不同功能参数
      ruleForm: {
        id: "",
        title: "",
        url: "",
        listorder: "1",
        type: 1,
        logistic_business_id: "",
        href: "",
        open: "0",
        status: "0",
      },
      childForm: {
        pid: "",
        id: "",
        name: "",
      },
      title: "",
      childTitle: "",
      centerDialogVisible: false,
      childDialogVisible: false,
      tableVisible: false,
      rules: {
        logistic_business_id: [
          { required: true, message: "请选择集配中心", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        url: [{ required: true, message: "请上传图片", trigger: "blur" }],
        listorder: [{ required: true, message: "请输入顺序", trigger: "blur" }],
      },
      childRules: {
        name: [{ required: true, message: "请输入品类名称", trigger: "blur" }],
      },
      url: [],
      pic: [],
      typeList: [], //类型列表
      radius_pic: [],
      bigImage: "",
      dialogImage: false,
      childDisabled: false,
      options: [],
    };
  },
  created() {
    this.hqlist();
    this.getAjaxBannerType();
    this.getBusinessList();
  },
  methods: {
    // 获取类型数据
    async getAjaxBannerType() {
      let { data } = await getBannerType();
      this.typeList = data;
    },
    // 获取集配中心数据
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    addclick() {
      console.log("新增");
      this.ruleForm = {
        id: "",
        title: "",
        url: "",
        listorder: "1",
        type: 1,
        logistic_business_id: "",
        href: "",
        open: "0",
        status: "0",
      };
      this.url = [];
      this.centerDialogVisible = true;
      this.title = "添加";
    },
    tianku() {
      console.log(this.ruleForm);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form = this.tool.DeepCopy({}, this.ruleForm);
          if (this.title == "添加") {
            delete form.id;
            this.tool.debounce(() => {
              this.$api.general.bannerAdd(form).then((res) => {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                this.centerDialogVisible = false;
                this.Refresh();
              });
            });
          } else {
            this.tool.debounce(() => {
              this.$api.general.bannerEdit(form).then((res) => {
                this.$message({
                  type: "success",
                  message: "编辑成功",
                });
                this.centerDialogVisible = false;
                this.hqlist();
              });
            });
          }
        }
      });
    },
    getGlass(src) {
      this.bigImage = src;
      this.showImage = true;
    },
    uploadFile(options, name) {
      // 拿到 file
      this.loadingImage = true;
      let file = options.file;
      let date = new Date().getTime();
      console.log(file, "文件");
      console.log(file.size / 1024 / 1024);
      compress(file, 0.9).then((res) => {
        console.log(res);
        console.log(res.size / 1024 / 1024);
        if (res.size / 1024 / 1024 > 50) {
          this.tool.message("图片超过50M,请重新上传", "error");
          this.loadingImage = false;
        } else {
          var event = event || window.event;
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onload = (e) => {
            console.log(e);
            this.uploadImage(e.target.result, name);
          };
        }
      });
    },
    async uploadImage(img, name) {
      try {
        let { data } = await postBannerUpload({ file_data: img });
        this[name] = [{ url: data }];
        this.ruleForm[name] = data;
      } catch (error) {
        console.error("ajax postBannerUpload err", err);
      } finally {
        this.loadingImage = false;
      }
    },
    handlePictureCardPreview(file) {
      console.log("object", file);
      this.bigImage = file.url;
      this.dialogImage = true;
    },
    deleteFile(file, fileList, name) {
      console.log(file, "删除");
      console.log(fileList, "删除");
      this[name] = fileList;
      this.ruleForm[name] = fileList;
    },
    Refresh() {
      this.from = {
        logistic_business_id: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        logistic_business_id: "",
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    handleEdit(index, row) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api.general
            .bannerDelete({
              id: row.id,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "已删除",
              });
              this.hqlist();
            });
        })
        .catch(() => {});
    },

    selectda(index, row) {
      this.title = "编辑";
      for (let key in this.ruleForm) {
        this.ruleForm[key] = row[key];
      }
      this.ruleForm.status = this.ruleForm.status + "";
      this.ruleForm.logistic_business_id =
        this.ruleForm.logistic_business_id || "";
      this.url = row.url ? [{ url: row.url }] : [];
      this.centerDialogVisible = true;
    },
    hqlist() {
      this.loading = true;
      this.$api.general.bannerList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.category {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}

// .el-picker-panel {
//     left: 1245px !important;
// }
</style>
