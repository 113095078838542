import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-banner-上传图片
 * @param {*} params
 * @returns
 */
export function postBannerUpload(params = {}) {
  return axios.post(`${BASE_URL}/banner/upload`, params);
}

/**
 * 总控-banner-类型
 * @param {*} params
 * @returns
 */
export function getBannerType(params = {}) {
  return axios.get(`${BASE_URL}/banner/type`, {params});
}
